import React from "react";
import { SettingsProvider } from "./src/context/SettingsContext";

require("@fontsource/plus-jakarta-sans");
require("@fontsource/plus-jakarta-sans/500.css");
require("@fontsource/plus-jakarta-sans/700.css");
require("@fontsource/plus-jakarta-sans/800.css");

require("./src/styles/global.scss");

export const wrapRootElement = ({ element }) => (
  <SettingsProvider>{element}</SettingsProvider>
);
