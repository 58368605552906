exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-browse-jsx": () => import("./../../../src/pages/browse.jsx" /* webpackChunkName: "component---src-pages-browse-jsx" */),
  "component---src-pages-categories-jsx": () => import("./../../../src/pages/categories.jsx" /* webpackChunkName: "component---src-pages-categories-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-signup-jsx": () => import("./../../../src/pages/signup.jsx" /* webpackChunkName: "component---src-pages-signup-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-templates-post-category-jsx": () => import("./../../../src/templates/post_category.jsx" /* webpackChunkName: "component---src-templates-post-category-jsx" */),
  "component---src-templates-single-post-jsx": () => import("./../../../src/templates/single-post.jsx" /* webpackChunkName: "component---src-templates-single-post-jsx" */)
}

